import React from 'react';

import { Link } from 'gatsby';

import {
	useIntl,
	FormattedMessage,
} from '../../../plugins/publicis-gatsby-plugin-i18n';
import NavLink from '../../Molecules/NavLink/NavLink';

import './Breadcrumb.scss';

let classNames = require('classnames');

const Breadcrumb = ({ page_banner, data }) => {
	const intl = useIntl();
	let { currentPage, parentPage, grandparentPage, gparent, actualite, locale } =
		data;
	if (currentPage.url === '/') {
		currentPage = null;
	}

	return (
		<div
			className={classNames('breadcrumb', { inside_page_banner: page_banner })}
		>
			<nav
				className='nav_breadcrumb'
				role='navigation'
				aria-label={intl.formatMessage({ id: 'ariaLabel.vous_etes_ici' })}
			>
				<ol itemScope itemType='http://schema.org/BreadcrumbList'>
					<li
						itemProp='itemListElement'
						itemScope
						itemType='http://schema.org/ListItem'
					>
						<NavLink
							match='exact'
							to={'/' + locale}
							itemScope
							itemType='http://schema.org/Thing'
							itemProp='item'
							itemID={'/' + locale}
						>
							<FormattedMessage id='common.goto.home'>
								{(x) => <span itemProp='name'>{x}</span>}
							</FormattedMessage>
						</NavLink>
						<meta itemProp='position' content='1' />
					</li>
					{gparent && gparent.title && (
						<li
							itemProp='itemListElement'
							itemScope
							itemType='http://schema.org/ListItem'
						>
							<span>&gt;</span>
							{gparent.url ? (
								<Link
									to={gparent.url}
									itemScope
									itemType='http://schema.org/Thing'
									itemProp='item'
									itemID={gparent.url}
								>
									<span itemProp='name'>{gparent.title}</span>
								</Link>
							) : (
								<a className='no_link'>
									<span itemProp='name' tabIndex={0}>
										{gparent.title}
									</span>
								</a>
							)}
							<meta itemProp='position' content='1' />
						</li>
					)}
					{grandparentPage && grandparentPage.title && (
						<li
							itemProp='itemListElement'
							itemScope
							itemType='http://schema.org/ListItem'
						>
							<span>&gt;</span>
							{grandparentPage.url ? (
								<Link
									to={grandparentPage.url}
									itemScope
									itemType='http://schema.org/Thing'
									itemProp='item'
									itemID={grandparentPage.url}
								>
									<span itemProp='name'>{grandparentPage.title}</span>
								</Link>
							) : (
								<a className='no_link'>
									<span itemProp='name' tabIndex={0}>
										{grandparentPage.title}
									</span>
								</a>
							)}
							<meta itemProp='position' content='1' />
						</li>
					)}
					{parentPage && parentPage?.title && (
						<li
							itemProp='itemListElement'
							itemScope
							itemType='http://schema.org/ListItem'
						>
							<span>&gt;</span>
							{parentPage.url ? (
								<Link
									to={parentPage.url}
									itemScope
									itemType='http://schema.org/Thing'
									itemProp='item'
									itemID={parentPage.url}
								>
									<span itemProp='name'>{parentPage.title}</span>
								</Link>
							) : (
								<a className='no_link'>
									<span itemProp='name' tabIndex={0}>
										{parentPage.title}
									</span>
								</a>
							)}
							<meta itemProp='position' content='1' />
						</li>
					)}
					{currentPage && (
						<li
							itemProp='itemListElement'
							itemScope
							itemType='http://schema.org/ListItem'
						>
							<span>&gt;</span>
							<Link
								to={currentPage.url}
								className='active'
								aria-current='page'
								itemScope
								itemType='http://schema.org/Thing'
								itemProp='item'
								itemID={currentPage.url}
							>
								<span itemProp='name'>{currentPage.title}</span>
							</Link>
							<meta itemProp='position' content='1' />
						</li>
					)}
					{actualite && (
						<li
							itemProp='itemListElement'
							itemScope
							itemType='http://schema.org/ListItem'
						>
							<span>&gt;</span>
							<Link
								to='/la-marque/actualite/[actualiteId]'
								as={`/la-marque/actualite/${actualite.url}`}
								className='active'
								aria-current='page'
								itemScope
								itemType='http://schema.org/Thing'
								itemProp='item'
								itemID={`/la-marque/actualite/${actualite.url}`}
							>
								<span itemProp='name'>{actualite.title}</span>
							</Link>
							<meta itemProp='position' content='1' />
						</li>
					)}
				</ol>
			</nav>
		</div>
	);
};

export default Breadcrumb;
